import { Button } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { ButtonActionsProps } from './ButtonActions.types';

export const ButtonActions = ({ actions, className }: ButtonActionsProps) => (
  <div className={classNames('flex items-center gap-3', className)}>
    {actions.map((action) => (
      <Button
        key={action.id}
        appearance={action.appearance}
        disabled={action.disabled}
        leftIcon={action.leftIcon}
        rightIcon={action.rightIcon}
        size={action.size}
        onClick={action.onClick}
        type={action.type}
        form={action.form}
      >
        {action.label}
      </Button>
    ))}
  </div>
);
