import { Input, useBaseSelectContext } from '~/components/granular';

export function SearchInput() {
  const { searchTerm, searchPlaceholder, handleSearchChange } = useBaseSelectContext();

  return (
    <div className="w-full border-neutral-200 border-b px-4 py-3">
      <Input
        height="small"
        placeholder={searchPlaceholder}
        value={searchTerm}
        leftIcon="SearchIcon"
        role="search"
        onChange={handleSearchChange}
      />
    </div>
  );
}
