import { type ChangeEvent, forwardRef, useMemo, useRef, useState } from 'react';
import { Button, Popover } from '~/components/granular';
import { useElementSize } from '~/hooks/useElementSize';
import { contextFactory } from '~/utils/contextFactory';
import type { BaseSelectContextProps, BaseSelectProps } from './BaseSelect.props';

// This component is a base select component that can be used to create custom select components.
// It shouldn't be used directly, but instead to compose other select components.

export const [BaseSelectContext, useBaseSelectContext] = contextFactory<BaseSelectContextProps>();

export const BaseSelect = forwardRef<HTMLDivElement, BaseSelectProps>(
  (
    {
      appearance = 'filter',
      applyLabel = 'Apply',
      backLabel = 'Back',
      children,
      disabled,
      items = [],
      label,
      leftIcon,
      loading,
      noDataLabel = 'No data',
      searchPlaceholder,
      selectedItems = [],
      size = 'small',
      width,
    },
    forwardedRef,
  ) => {
    const triggerRef = useRef<HTMLButtonElement | null>(null);

    const { width: elementWidth } = useElementSize(triggerRef);

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    function handleToggleOpen(isOpen: boolean) {
      if (!isOpen) setSearchTerm('');
      setIsOpen(isOpen);
    }

    function handleSearchChange(event?: ChangeEvent<HTMLInputElement>) {
      setSearchTerm(event ? event.target.value : '');
    }

    const filteredItems = useMemo(() => {
      return items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [items, searchTerm]);

    return (
      <div ref={forwardedRef}>
        <Popover
          align="start"
          direction="bottom"
          trigger={
            <div className="inline-flex">
              <Button
                ref={triggerRef}
                appearance={appearance}
                size={size}
                leftIcon={leftIcon}
                rightIcon="ArrowDownSIcon"
              >
                {label}
              </Button>
            </div>
          }
          onTriggerClick={(open) => handleToggleOpen(open)}
          open={isOpen}
          contentWidth={width ?? elementWidth}
          disabled={disabled}
        >
          <BaseSelectContext.Provider
            value={{
              applyLabel,
              backLabel,
              items: filteredItems,
              loading,
              noDataLabel,
              searchTerm,
              searchPlaceholder,
              selectedItems,
              width: width ?? elementWidth,
              handleToggleOpen,
              handleSearchChange,
            }}
          >
            {children}
          </BaseSelectContext.Provider>
        </Popover>
      </div>
    );
  },
);
