import { forwardRef, useMemo, useState } from 'react';
import { BaseSelect, Button, Checkbox, useBaseSelectContext } from '~/components/granular';
import { BackButton } from '../components/back-button';
import { Loading } from '../components/loading';
import { NoData } from '../components/no-data';
import { SearchInput } from '../components/search-input';
import { SelectOption } from '../components/select-option';
import { VirtualisedSelectList } from '../components/virtualised-select-list';
import type { SelectGroup, SelectItem, TagSelectProps } from './TagSelect.props';

export const TagSelect = forwardRef<HTMLDivElement, TagSelectProps>(
  (
    {
      appearance = 'filter',
      applyLabel,
      backLabel,
      disabled,
      items = [],
      selectedItems = [],
      leftIcon,
      loading,
      noDataLabel,
      placeholder,
      searchPlaceholder,
      label,
      onApplySelection,
    },
    forwardedRef,
  ) => {
    return (
      <BaseSelect
        ref={forwardedRef}
        appearance={appearance}
        applyLabel={applyLabel}
        noDataLabel={noDataLabel}
        backLabel={backLabel}
        disabled={disabled}
        items={items}
        leftIcon={leftIcon}
        loading={loading}
        placeholder={placeholder}
        selectedItems={selectedItems}
        searchPlaceholder={searchPlaceholder}
        label={label}
        width={300}
      >
        <GroupedSelect onApplySelection={onApplySelection} />
      </BaseSelect>
    );
  },
);

function GroupedSelect({ onApplySelection }: { onApplySelection: (group: SelectGroup) => void }) {
  const { selectedItems, items, loading, searchTerm, applyLabel, handleToggleOpen, handleSearchChange } =
    useBaseSelectContext();

  const [activeGroup, setActiveGroup] = useState<SelectGroup | null>(null);
  const [tempChildSelection, setTempChildSelection] = useState<Array<SelectItem>>(selectedItems);

  function handleGroupClick(item: SelectItem | SelectGroup) {
    if ('children' in item) {
      handleSearchChange();
      setActiveGroup(item);
    }
  }

  function resetState() {
    setActiveGroup(null);
    handleSearchChange();
    setTempChildSelection([]);
  }

  function handleTempChildSelection(item: SelectItem | SelectGroup) {
    if (tempChildSelection.includes(item)) {
      setTempChildSelection((prev) => prev.filter((prevItem) => prevItem !== item));
    } else {
      setTempChildSelection((prev) => [...prev, item]);
    }
  }

  function handleApplySelection() {
    if (activeGroup && tempChildSelection.length) {
      onApplySelection({ ...activeGroup, children: tempChildSelection });
    }

    resetState();
    handleToggleOpen(false);
  }

  const childItems = useMemo(() => {
    if (!activeGroup) return [];
    return activeGroup.children.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [activeGroup, searchTerm]);

  const isItemSelected = (item: SelectItem) => tempChildSelection.includes(item);

  return (
    <>
      <BackButton visible={!!activeGroup} onClick={resetState} />
      <SearchInput />

      {loading && <Loading />}

      {!items.length && !childItems.length && <NoData />}

      {!loading && !activeGroup ? (
        <VirtualisedSelectList>
          {({ data, style, index }) => (
            <SelectOption style={style} item={data[index]} onItemClick={handleGroupClick}>
              {data[index].label}
            </SelectOption>
          )}
        </VirtualisedSelectList>
      ) : (
        <VirtualisedSelectList items={childItems}>
          {({ data, style, index }) => (
            <SelectOption
              style={style}
              item={data[index]}
              isSelected={isItemSelected(data[index])}
              onItemClick={handleTempChildSelection}
            >
              <Checkbox
                id={data[index].value}
                className="overflow-hidden whitespace-nowrap"
                name={data[index].value}
                checked={isItemSelected(data[index])}
                label={data[index].label}
              />
            </SelectOption>
          )}
        </VirtualisedSelectList>
      )}

      {activeGroup && (
        <div className="flex border-neutral-200 border-t px-4 py-3">
          <Button size="small" className="!justify-center flex-grow" onClick={handleApplySelection}>
            {applyLabel}
          </Button>
        </div>
      )}
    </>
  );
}
