import type { CSSProperties, KeyboardEvent, ReactNode } from 'react';
import type { SelectGroup, SelectItem } from '~/components/granular';
import { classNames } from '~/utils/styles';

export interface SelectOptionProps {
  /** The item to display */
  item: SelectItem | SelectGroup;
  /** The style of the item for the virtualised list */
  style: CSSProperties;
  /** Optional class name */
  className?: string;
  /** Sets the whether the item has been selected */
  isSelected?: boolean;
  /** Sets the whether the item is disabled */
  disabled?: boolean;
  //** The child component that get rendered */
  children?: ReactNode;
  /** Gets called when the item is clicked */
  onItemClick: (item: SelectItem | SelectGroup) => void;
}

export function SelectOption({
  item,
  children,
  style,
  className,
  isSelected,
  disabled,
  onItemClick,
}: SelectOptionProps) {
  function handleOnKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') onItemClick(item);
  }

  const classes = classNames(
    'body-200-light flex w-full items-center px-4 text-neutral-1000',
    'hover:cursor-pointer hover:bg-neutral-200 focus-visible:bg-neutral-200 focus-visible:outline-none',
    {
      'bg-teal-300 hover:bg-teal-300': isSelected,
      '!cursor-not-allowed text-neutral-400 hover:bg-transparent': disabled,
    },
    className,
  );

  return (
    <div
      className={classes}
      role="option"
      aria-selected="false"
      style={style}
      onClick={() => onItemClick(item)}
      onKeyDown={(evt) => handleOnKeyDown(evt)}
      tabIndex={0}
    >
      {children}
    </div>
  );
}
